import { useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./App.css";
import { Modal } from "./components/General";
import emailReducer from "./store/reducers/emailsReducer";
import { EMAIL_ACTION_TYPE } from "./store/actions/types";
import { TopMenu, SideMenu } from "./components/Navigation";
import Router from "./routes/Router";
import { fetchEmailIds } from "./firebase/firestore/emails";
import { GlobalStyles } from "@mui/system";

const App = () => {
  const [emailState, emailDispatch] = useReducer(emailReducer, {
    list: [],
    userLoggedIn: false,
  });

  const [openModal, setOpenModal] = useState<boolean>(false);

  const setList = (list: any) => {
    emailDispatch({
      type: EMAIL_ACTION_TYPE.FETCH_EMAILS,
      payload: { list: list },
    });
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      fetchEmailIds().then((emailList: any) => setList(emailList));
    }
  }, [location]);

  const toggleModal = () => setOpenModal((prev) => !prev);

  const onConfirm = () => {
    navigate("/");
    toggleModal();
  };
  return (
    <>
      <GlobalStyles styles={{}} />
      <Modal
        open={openModal}
        handleClose={toggleModal}
        onConfirm={onConfirm}
        subtitle="Are you sure you want to navigate away from this page without saving?"
      />
      <TopMenu toggleModal={toggleModal} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "-webkit-fill-available",
          paddingTop: "60px",
          width: "-webkit-fill-available",
        }}
      >
        {!location.pathname.includes("login") && (
          <SideMenu toggleModal={toggleModal} />
        )}
        <Router emailState={emailState} emailDispatch={emailDispatch} />
      </div>
    </>
  );
};

export default App;
