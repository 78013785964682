interface EmailQuery {
  to: string;
  subject: string;
  queryID: string;
  textContent: string;
}
export const sendEmail = async (
  query: EmailQuery
): Promise<void | Response> => {
  return fetch(
    "https://europe-west1-eresolvevaademo.cloudfunctions.net/sendEmail",
    {
      method: "POST",
      body: JSON.stringify(query),
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      // console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Error:", error);
    });
};

export type RegeneratePromptType =
  | "basic"
  | "formal"
  | "informal"
  | "more"
  | "less";

export const regenerateResponse = async ({
  type = "basic",
  queryID,
}: {
  type?: RegeneratePromptType;
  queryID: string;
}) => {
  return fetch(
    "https://europe-west1-eresolvevaademo.cloudfunctions.net/sendEmail",
    {
      method: "POST",
      body: JSON.stringify({ type, queryID }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text();
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error("Error:", error);
    });
};
